var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isBusy)?_c('slick',{attrs:{"options":_vm.slickOptions}},[_vm._l((_vm.galleryArtistRecentArts),function(item,i){return [(item.idx !== _vm.artwork)?_c('article',{key:i,staticClass:"item",on:{"click":function($event){return _vm.onClickArtwork(item.idx)}}},[_c('v-lazy',{attrs:{"options":{ threshold: 0.5 },"transition":"fade-transition"}},[_c('div',{staticClass:"img-box",on:{"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();}}},[_c('b-img',{style:({
              display: _vm.isProductFileTypeImage(item.displayCard)
                ? 'block'
                : 'none',
            }),attrs:{"src":_vm.replaceThumbnailUrlV2(item)}}),_c('video',{style:({
              display: _vm.isProductFileTypeImage(item.displayCard)
                ? 'none'
                : 'block',
            }),attrs:{"poster":_vm.replaceThumbnailUrlV2(item),"src":_vm.replaceVideoThumbnailUrl(item),"type":"video/webm","muted":"","loop":"","autoplay":"","playsinline":""},domProps:{"muted":true}})],1)]),_c('section',{staticClass:"card-info"},[(!_vm.isBusy)?_c('section',{staticClass:"card-info__header"},[_c('p',{staticClass:"name",on:{"click":function($event){$event.stopPropagation();return _vm.onClickArtist.apply(null, arguments)}}},[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[_vm._v(" "+_vm._s(item.artistName || item.artistNameEn)+" ")]:[_vm._v(" "+_vm._s(item.artistNameEn || item.artistName)+" ")]],2),_c('FavoritedButton',{attrs:{"favoritedItem":item},on:{"onClickFavoritedButton":_vm.onClickFavoritedButton}})],1):_c('section',{staticClass:"card-info__header"},[_c('p',{staticClass:"artist_name"},[_c('b-skeleton',{staticClass:"float-left",attrs:{"type":"avatar","height":"30px","width":"30px"}}),_c('b-skeleton',{staticClass:"float-left mt-2 ml-2",attrs:{"width":"100px"}})],1)]),_c('h3',{staticClass:"card-info__title"},[(_vm.activeLanguage !== undefined && _vm.activeLanguage === 'ko')?[_vm._v(" "+_vm._s(item.productName || item.productNameEn)+" ")]:[_vm._v(" "+_vm._s(item.productNameEn || item.productName)+" ")]],2),(!_vm.isBusy)?_c('section',{staticClass:"card-info__price"},[_c('p',{staticClass:"price-label"},[_vm._v("price")]),_c('p',{staticClass:"price-value",class:{
              soldout:
                item.status === 'SOLDOUT' || item.status === 'PRIVATESOLD',
            }},[(
                item.status === 'SOLDOUT' || item.status === 'PRIVATESOLD'
              )?[_vm._v(" "+_vm._s(item.buyerNickname !== undefined && item.buyerNickname !== "" ? "Owned by @" + item.buyerNickname : _vm.$t("product.statusSoldOut"))+" ")]:(item.status === 'EXHIBIT')?[_vm._v(" "+_vm._s(_vm.$t("product.statusExhibit"))+" ")]:(
                item.nftType === 'ORDINALS' &&
                _vm.activeLanguage !== undefined &&
                _vm.activeLanguage === 'en'
              )?[_vm._v(" "+_vm._s(_vm.getSatoshiToBtc(item.productCoinPrice))+" "+_vm._s(_vm.getCoinCurrencyByNftType(item.nftType))+" ")]:(item.nftType === 'ORDINALS')?[_vm._v(" "+_vm._s(_vm.getSatoshiToKRW(item.productCoinPrice))+" KRW ")]:(
                _vm.activeLanguage !== undefined &&
                _vm.activeLanguage === 'en' &&
                _vm.isCoinPriceNonZero(item.nftType) &&
                _vm.isAcceptableNftType(item.nftType)
              )?[_vm._v(" "+_vm._s(_vm.getProductCoinPrice(item.productPrice, item.nftType))+" "+_vm._s(_vm.getCoinCurrencyByNftType(item.nftType))+" ")]:[_vm._v(" "+_vm._s(_vm._f("currency")(item.productPrice))+" "+_vm._s(item.productPrice !== undefined && item.productPrice > 0 ? "KRW" : _vm.$t("product.statusExhibit"))+" ")]],2)]):_c('section',{staticClass:"card-info__price"},[_c('span',[_c('b-skeleton',{attrs:{"width":"50%","height":"25px"}})],1)])])],1):_vm._e()]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }