<template>
  <div id="vue-wepin"></div>
</template>

<script>
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import {mapMutations} from "vuex";
import WalletConnectUtil from "@/mixins/WalletConnectUtil";
import { WepinLogin } from '@wepin/login-js'
import { WepinSDK } from '@wepin/sdk-js'


export default {
  data() {
    return {
      connected: false,
      provider: null,
      chainId: null,
      address: null,
      asset: null,
      wepinSdk: null,
    };
  },
  mixins: [WalletConnectUtil],
  methods: {
    ...mapMutations("wallet", ["setWalletProvider"]),
    resetState() {
      this.$emit("onComplete", {
        chainId: null,
        address: null,
        network: null,
        asset: null,
        provider: null,
      });
    },
    async initWepin() {
      this.wepinSdk = new WepinSDK({
        appKey: 'ak_live_3SEPmpX8MbMaxJXndMqpANE2YO6VJ58hdTU6jDAHPbC',
        appId: '1fc3dc4060a5319c0a698acd69f40c64',
      })

      await this.wepinSdk.init({
        defaultLanguage: 'ko',
      })
    },
    checkAccounts(network) {
      const {chainId, accounts} = this.provider;
      const { peer } = this.provider.session;
      const address = accounts[0];
      const walletProvider = "wc-"+peer.metadata.name;

      const walletInfo = {
        chainId: chainId,
        addr: address,
        network: network,
        walletProvider: walletProvider
      }

      if (network.toUpperCase() === "POLYGON") {
        walletInfo.polygonAddr = address;
      }else {
        walletInfo.ethereumAddr = address;
      }

      this.$emit("wallet-info", {...walletInfo});
    },
    async killSession() {
      if (this.provider.connector) {
        await this.provider.connector.killSession();
      }
      this.resetState();
    },
    async onClick(network) {
      if(!this.wepinSdk) {
        await this.initWepin();
      }

      const res = await this.wepinSdk.loginWithUI()

      if(res.userStatus.loginStatus !== 'complete') {
        const registerRes = await this.wepinSdk.register()
        this.$emit("onComplete", registerRes.userInfo)
      }

      this.$emit("onComplete", res.userInfo)
    },
    async openWidget() {
      if(!this.wepinSdk) {
        await this.initWepin();
      }

      await this.wepinSdk.openWidget();
    }
  }
};
</script>

<style scoped>
  #vue-wepin {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }
</style>